import { notifyException/*, notifySuccess*/ } from "@/state/helpers";

import i18n from "@/utils/i18n";

const formMode = {
    View: "VIEW",
    Edit: "EDIT",
    Create: "CREATE"
}
//mapFielsEntityToView deve ser um array com objetos {entity: '', form: ''} onde
//podem ser mapeados campos das entidades com nomes diferentes no formulario
const checkApiError = (err, $externalResults, mapFielsEntityToView = []) => {
    if (err && err.response && err.response.data && err.response.data.modelErrors){
        var errors = {};
        
        
        err.response.data.modelErrors.forEach(errorItem => {
            let field = errorItem.field;
            if (mapFielsEntityToView.length > 0 && mapFielsEntityToView.filter(m => m.entity == errorItem.field).length > 0) {
                field = mapFielsEntityToView.filter(m => m.entity == errorItem.field)[0].form
            }
            errors[field] = errorItem.errors
        });
        //console.log(err.response.data.modelErrors)
        $externalResults.value = errors

    } else {
        notifyException(err)
    }
}

const emitSuccessMessageAndRedirect = (msg, router, route) => {
    router.push(route) //todo
    /*notifySuccess(msg, 5, {
        onClose: () => {
            router.push(route)
        }
    })*/
}

const isEditando = (currentRoute) => {
    return currentRoute && currentRoute.meta && currentRoute.meta.formMode == formMode.Edit
}

const isCriando = (currentRoute) => {
    return currentRoute && currentRoute.meta && currentRoute.meta.formMode == formMode.Create
}

const isVisualizando = (currentRoute) => {
    return currentRoute && currentRoute.meta && currentRoute.meta.formMode == formMode.View
}

const getPathTitle = (currentRoute) => {
    if (isEditando(currentRoute)) {
        return i18n.global.t('editar')
    }
    if (isCriando(currentRoute)) {
        return i18n.global.t('criar')
    }
    if (isVisualizando(currentRoute)) {
        return i18n.global.t('visualizar')
    }
    return ''
}

const getSubHeader = (prefix, currentRoute) => {
    if (currentRoute && currentRoute.meta && currentRoute.meta.formMode) {
        return i18n.global.t(`${prefix}-${currentRoute.meta.formMode.toLowerCase()}-subheader`)
    }
    return ''
}

export {
    checkApiError,
    isEditando,
    isCriando,
    isVisualizando,
    formMode,
    getPathTitle,
    getSubHeader,
    emitSuccessMessageAndRedirect
}