import WaitDataLoad from "@/components/wait-data-load"
import TextInput from '@/components/inputs/input.vue'
import DateInput from '@/components/inputs/date.vue'
import SelectInput from '@/components/inputs/select.vue'
import CheckboxGroupInput from '@/components/inputs/checkbox-group'

const setup = (app) => {
    app.component("WaitDataLoad", WaitDataLoad);
    app.component("TextInput", TextInput);
    app.component("SelectInput", SelectInput);
    app.component("CheckboxGroupInput", CheckboxGroupInput);
    app.component("DateInput", DateInput);
} 

export default setup