<template>
    <BCol :md="props.cols">
        <label :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
        <BFormInput 
            :type="props.type" :state="v == null || v.$errors.length == 0" trim 
            v-model="value" :disabled="$attrs.disabled" :readonly="$attrs.readonly"
        ></BFormInput>
        <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error${index}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<script setup>
    import { defineProps, defineEmits, computed } from "vue"
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({
        modelValue: {  },
        required: { type: Boolean, default: false },
        label: { type: String, required: true },
        cols: { type: Number, required: true },        
        type: { type: String, required: false, default: 'text' },
        v: { type: Object, required: false, default: null }
    })
    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }
    const value = computed({
        get: () => {
            return props.modelValue
        },
        set: (v) => {
            emit('update:modelValue', v)
        }
    })

</script>