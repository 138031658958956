import { createI18n } from 'vue-i18n';
const currentLocale = () => {
  let lang = localStorage.getItem('eon.lang')
  if (!lang) {
      return 'pt'
  }
  if (lang.length > 2) {
      return lang.substring(0, 2).toLowerCase()
  }
  return lang.toLowerCase()

}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('../lang', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: currentLocale(),
  fallbackLocale: currentLocale(),
  messages: loadLocaleMessages(),
  globalInjection: true,
});
export default i18n;


import * as validators from '@vuelidate/validators'
const { createI18nMessage } = validators
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

// wrap each validator.
export const required = withI18nMessage(validators.required, { withArguments: true })
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
// wrap each validator.
export const email = withI18nMessage(validators.email)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
//export 
//helpers.withMessage('This field cannot be empty', required)

const sameAsCustom = withI18nMessage(
  validators.sameAs, 
  {  
    withArguments: true, 
    messagePath: ({$params}) => i18n.global.t('validations.sameAs', [ $params.otherName ])
  }
)
export const sameAs =  (name, other) =>  validators.helpers.withParams( { type: 'sameAs'  }, sameAsCustom(other, name))

