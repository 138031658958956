import i18n from "@/utils/i18n";

const routes = [
  // auth
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: i18n.global.t('title.login'),
      authRequired: false
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      title: i18n.global.t('title.recovery_pass'),
      authRequired: false
    },
  },
  {
    path: "/link-change-password/:code",
    name: "link-change-password",
    component: () => import("../views/account/change-password-link"),
    meta: {
      title: i18n.global.t('title.link_change_pass'),
      authRequired: false
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: { 
      title: "Logout", 
      authRequired: false, 
      beforeResolve(routeTo, routeFrom, next) { 
        localStorage.clear(); 
        sessionStorage.clear(); 
        next(); 
      }, 
    },
    component: () => import("../views/account/logout"),
  },
  //
  {
    path: "/minha-conta",
    name: "minha-conta",
    meta: { 
      title: i18n.global.t('minha-conta'), 
      authRequired: true
    },
    component: () => import("../views/account/minha-conta"),
  },
];
export default routes;
