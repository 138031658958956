<template>
    <BCol :md="props.cols">
        <label :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
        <FlatPickr 
            v-model="value"
            :class="`form-control${(v == null || v.$errors.length == 0 ? '' : ' border-danger')}`" 
            :state="v == null || v.$errors.length == 0"
            :disabled="$attrs.disabled" :readonly="$attrs.readonly"
            :config="{ altInput: true, altFormat: 'd M, Y', dateFormat: 'Y-m-d', locale: ptLocale }">
        </FlatPickr>
        <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error${index}`">{{ error.$message }}</BFormInvalidFeedback>
    </BCol>
</template>

<script setup>
    import { defineProps, defineEmits, computed } from "vue"
    import FlatPickr from "vue-flatpickr-component";
    import {Portuguese as ptLocale} from 'flatpickr/dist/l10n/pt';
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({
        modelValue: {  },
        required: { type: Boolean, default: false },
        label: { type: String, required: true },
        cols: { type: Number, required: true },        
        v: { type: Object, required: false, default: null }
    })
    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }
    const value = computed({
        get: () => {
            return props.modelValue
        },
        set: (v) => {
            emit('update:modelValue', v)
        }
    })

</script>