import { mapState, mapGetters, mapActions } from 'vuex';
import store from './store';

export const accountComputed = {
  ...mapState('account', {
    username: (state) => state.username,
    userId: (state) => state.userId,
    token: (state) => state.token,
    language: (state) => state.language,
  }),
  ...mapGetters('account', ['loggedIn', 'getLanguage', 'userName', 'userEmail']),
};

export const layoutComputed = {
  ...mapState('layout', {
    mode: (state) => state.mode,
    cardLayout: (state) => state.cardLayout,
    layoutWidth: (state) => state.layoutWidth,
    position: (state) => state.position,
    topbar: (state) => state.topbar,
    topbarImage: (state) => state.topbarImage,
    preloader: (state) => state.preloader,
  })
};

export const layoutMethods = mapActions('layout',
  ['changeMode', 'changeCardLayout', 'changeLayoutWidth', 'changePosition', 'changeTopbar', 'changeTopbarImage', 'changePreloader']);

export const logIn = (model) => store.dispatch('account/logIn', model)
export const updateToken = (model) => store.dispatch('account/updateToken', model)
export const updateLanguage = (language) => store.dispatch('account/updateLanguage', language)

export const notifyException = (err, timeInSeconds = 10, options = {}) =>  store.dispatch('notification/notifyException', { err, timeInSeconds, options })
export const notifySuccess = (message, timeInSeconds = 4, options = {}) => store.dispatch('notification/notifySuccess', { message, timeInSeconds, options })
export const notifyError = (message, timeInSeconds = 8, options = {}) => store.dispatch('notification/notifyError', { message, timeInSeconds, options })
export const notifyWarning = (message, timeInSeconds = 6, options = {}) => store.dispatch('notification/notifyWarning', { message, timeInSeconds, options })
export const notifyInfo = (message, timeInSeconds = 4, options = {}) => store.dispatch('notification/notifyInfo', { message, timeInSeconds, options })

export const toggleModalWait = (text = '') => store.dispatch('notification/toggleModalWait', text)
export const notificationComputed = {
  ...mapGetters('notification', ['modalWaitVisible']),
};
