<template>
    <BCol :md="props.cols">
        <label :class="`form-label${props.required ? ' form-label-required' : ''}`">{{ props.label }}</label>
        <div>
            <span v-if="props.options.length > 0 && !$attrs.disabled" @click="clickCheckAll()">
                <i v-if="isUndetermined()" class="fa-lg fa-light fa-square-minus"></i>
                <i v-if="isUncheckedAll()" class="fa-lg fa-light fa-square"></i>
                <i v-if="isCheckedAll()" class="fa-lg fa-light fa-square-check"></i>
                <span class="ps-2 fst-italic fw-lighter">{{ $t('select-un-all') }}</span>
            </span>
            <BFormCheckboxGroup
                :disabled="$attrs.disabled"
                v-model="value"
                :state="v == null || v.$errors.length == 0" 
                :options="props.options"
                :stacked="props.stacked"
                class="mt-1"
            >
                <BFormInvalidFeedback v-for="(error, index) of errors()" :key="`error${index}`">{{ error.$message }}</BFormInvalidFeedback>
            </BFormCheckboxGroup>
        </div>

       
    </BCol>
</template>

<script setup>
    import { defineProps, defineEmits, computed } from "vue"
    
    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({
        modelValue: {  },
        required: { type: Boolean, default: false },
        label: { type: String, required: true },
        cols: { type: Number, required: true },
        type: { type: String, required: false, default: 'text' },
        options: { type: Array, required: true, default: new Array() },
        stacked: { type: Boolean, default: true },
        v: { type: Object, required: false, default: null }
    })
    const errors = () => {
        if (props.v == null) {
            return []
        }
        return props.v.$errors
    }
    const value = computed({
        get: () => {
            return props.modelValue
        },
        set: (v) => {
            emit('update:modelValue', v)
        }
    })

    const clickCheckAll = () => {
        if (isUndetermined() || isCheckedAll()) {
            emit('update:modelValue', [])
        } else {
            //adicionar todos
            emit('update:modelValue', props.options.map(m => m.value))
        }
    }
    const isUndetermined = () => {
        if ((!value.value || value.value.length == 0) && props.options.length > 0) {
            return false;
        }
        return props.options.length != value.value.length
    }
    const isUncheckedAll = () => {
        if (!value.value) {
            return true;
        }
        return props.options.length == 0 || value.value.length == 0
    }
    const isCheckedAll = () => {
        if (!value.value || props.options.length == 0) {
            return false;
        }
        return props.options.length == value.value.length
    }

</script>