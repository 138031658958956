import i18n from "@/utils/i18n";
const addGlobalLabelsProps = (app) => {
    app.config.globalProperties.$labels = {
        getStatusLabel(value) {
            if (value) {
                switch(value.toUpperCase()) {
                    case 'A':
                        return i18n.global.t('ativo')
                    case 'I':
                        return i18n.global.t('inativo')
                }
            }
            return value;
        },
        getTipoLogLabel(value) {
            if (value) {
                return i18n.global.t(`tp-log.${value.toLowerCase()}`)
            }
            return value;
        }
    }
}

const selectAtivoInativo = [
    { value: 'A', text: i18n.global.t('ativo') },
    { value: 'I', text: i18n.global.t('inativo') },
]

const toSelectOptionsTranslated = (apiOptions) => {
    let result = [];
    apiOptions.forEach(e => {
        result.push({
            value: e.value,
            text: i18n.global.t(e.text ? e.text : e.label)
        })
    })
    return result;
}

export default addGlobalLabelsProps
export {
    selectAtivoInativo,
    toSelectOptionsTranslated
}
