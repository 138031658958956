import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./state/store";
import BootstrapVueNext from 'bootstrap-vue-next';
import i18n from './utils/i18n';
import addMomentFilters from './utils/moment.filters';
import setupAppComponents from './utils/globalComponents';
import addAuthorizeDirective from './utils/authorize.directive'
import addGlobalLabelsProps from './utils/labels.helpers'

import { vMaska } from "maska";

import './assets/scss/app.scss';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

const app = createApp(App);

addMomentFilters(app);
addAuthorizeDirective(app);
addGlobalLabelsProps(app);
setupAppComponents(app)



app.use(router)
    .use(store)
    .use(BootstrapVueNext)
    .use(i18n)
    .directive("maska", vMaska)
    .mount('#app');
