<template>
  <router-view></router-view>
  <ModalWait />
</template>

<style></style>

<script>

import ModalWait from "@/components/modal-wait.vue";
export default {
  name: 'App',
  components: {
    ModalWait
  }
};
</script>
