import axios from 'axios';
import store from '@/state/store';
import router from '../router/index'
import { notifyError } from '@/state/helpers';

export default class ApiRest {
    constructor() {
    }

    getClient(timeout = 10000, addInterceptor401 = true, responseType = '') {
        var client = axios.create({
            baseURL: process.env.VUE_APP_APIURL,
            timeout: timeout,
            responseType: responseType,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': store.getters['account/getLanguage']
            }
        });

        if (addInterceptor401) {
            client.interceptors.response.use(
                (response) =>  response,
                (error) => {
                    if (error.response && error.response.status === 401) {
                        if (router.currentRoute.value.meta.authRequired) {
                            router.push('/login?url=' + router.currentRoute.value.path);
                        }
                    } else {
                        if (!error.response && error.message) {
                            notifyError(error.message)
                        }
                        return Promise.reject(error);
                    }                
                }
            );
        }
        return client;
    }

    async post(path, params, reqAuth = true, headers = [], timeout = 180000, responseType = '') {
        if (reqAuth) {
            return this.getClient(timeout, true, responseType).post(path, params, this.getToken(headers));
        } else {
            return this.getClient(timeout, false, responseType).post(path, params);
        }
    }

    async put(path, params, reqAuth = true, timeout = 180000, responseType = '') {
        if (reqAuth) {
            return this.getClient(timeout, true, responseType).put(path, params, this.getToken());
        } else {
            return this.getClient(timeout, false, responseType).put(path, params);
        }
    }

    async delete(path, params, reqAuth = true, timeout = 180000, responseType = '') {
        var queryString = Object.keys(params).map(function (key) {
            return key + '=' + params[key]
        }).join('&');
        if (reqAuth) {
            return this.getClient(timeout, true, responseType).delete(`${path}?${queryString}`, this.getToken());
        } else {
            return this.getClient(timeout, false, responseType).delete(`${path}?${queryString}`);
        }
    }

    getParamValue(vl) {
        return vl === null || vl === undefined ? '' : vl
    }
    getArrayToQueryString(array, fieldName, parentKey) {
        if (array.length > 0) {
            let result = '';
            array.forEach((element, index) => {
                result += 
                    Object.keys(element).map((key) => {
                    
                        if (Array.isArray(element[key])) {
                            return this.getArrayToQueryString(element[key], key, `${parentKey}${fieldName}[${index}].`)
                        } else {
                            if (isNaN(key)) {
                                return `${parentKey}${fieldName}[${index}].${key}=${this.getParamValue(element[key])}`
                            } else {
                                return `${parentKey}${fieldName}[${index}]=${this.getParamValue(element[key])}`
                            }
                        }
                    })
                    .join('&') + '&';
            });
            return result;
            
        }
        return fieldName + '='
        
    }

    getQueryStringParam(object, key) {
        if (Array.isArray(object[key])) {
            return this.getArrayToQueryString(object[key], key, '')
        } else {
            return key + '=' + this.getParamValue(object[key])
        }
    }
    async get(path, params, reqAuth = true, timeout = 180000, responseType = '') {
        var queryString = Object.keys(params).map((key) => this.getQueryStringParam(params, key)).join('&');
        if (reqAuth) {
            return this.getClient(timeout, true, responseType).get(`${path}?${queryString}`, this.getToken());
        } else {
            return this.getClient(timeout, false, responseType).get(`${path}?${queryString}`);
        }
    }

    getToken(headers = []) {
        var r = {
            headers: {
                'Authorization': "Bearer " + store.getters['account/token'],
            }
        };
        for (var idx = 0; idx < headers.length; idx++) {
            r.headers[headers[idx].name] = headers[idx].value;
        }
        return r;
    }
}