import store from "@/state/store"

const checkAuthorize = (el, roles) => {
    let userRoles = store.getters['account/roles']
    if (roles.value != null) {
        var found = false;
        if (Array.isArray(roles.value)) {
            for (var idx = 0; idx < roles.value.length; idx++) {
                if (userRoles.indexOf(roles.value[idx]) != -1) {
                    found = true;
                    break;
                }
            }
        } else {
            found = userRoles.indexOf(roles.value) != -1;
        }
        if (!found) {
            el.remove();
        }
    }
}

const addAuthorizeDirective = (app) => {
    app.directive('authorize', {
        mounted(el, roles) {
            checkAuthorize(el, roles)
        },
        updated(el, roles) {
            checkAuthorize(el, roles)
        }
    })
}

export default addAuthorizeDirective
