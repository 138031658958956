import moment from 'moment'
const addMomentFilters = (app) => {
    app.config.globalProperties.$moment = {
        formatDate(date) {
            if (!date) { return '' }
            return moment(date).format("DD/MM/YYYY")
        },

        formatDateTime(date) {
            if (!date) { return '' }
            return moment(date).format("DD/MM/YYYY HH:mm")
        },
    }
}

export default addMomentFilters
