import i18n from "@/utils/i18n";
import { formMode } from "@/utils/form.helpers"

const routesUsuarios = [
  {
    path: "/seguranca/usuarios",
    name: "seguranca-usuarios",
    component: () => import("../views/seguranca/usuarios/index"),
    meta: {
      title: i18n.global.t('seg.user'),
      authRequired: true,
      roles: ['seg.user']
    },
  },
  {
    path: "/seguranca/usuarios/criar",
    name: "seguranca-usuarios-criar",
    component: () => import("../views/seguranca/usuarios/form"),
    meta: {
      title: i18n.global.t('seg.user.create'),
      formMode: formMode.Create,
      authRequired: true,
      roles: ['seg.user.create']
    },
  },
  {
    path: "/seguranca/usuarios/editar/:id",
    name: "seguranca-usuarios-editar",
    component: () => import("../views/seguranca/usuarios/form"),
    meta: {
      title: i18n.global.t('seg.user.edit'),
      formMode: formMode.Edit,
      authRequired: true,
      roles: ['seg.user.edit']
    },
  },
  {
    path: "/seguranca/usuarios/visualizar/:id",
    name: "seguranca-usuarios-visualizar",
    component: () => import("../views/seguranca/usuarios/form"),
    meta: {
      title: i18n.global.t('seg.user'),
      formMode: formMode.View,
      roles: ['seg.user'],
      authRequired: true
    },
  }

];

const routesRoles = [
  {
    path: "/seguranca/papeis-funcoes",
    name: "seguranca-roles",
    component: () => import("../views/seguranca/roles/index"),
    meta: {
      title: i18n.global.t('seg.role'),
      authRequired: true,
      roles: ['seg.role']
    },
  },
  {
    path: "/seguranca/papeis-funcoes/criar",
    name: "seguranca-roles-criar",
    component: () => import("../views/seguranca/roles/form"),
    meta: {
      title: i18n.global.t('seg.role.create'),
      formMode: formMode.Create,
      authRequired: true,
      roles: ['seg.role.create']
    },
  },
  {
    path: "/seguranca/papeis-funcoes/editar/:id",
    name: "seguranca-roles-editar",
    component: () => import("../views/seguranca/roles/form"),
    meta: {
      title: i18n.global.t('seg.role.edit'),
      formMode: formMode.Edit,
      authRequired: true,
      roles: ['seg.role.edit']
    },
  },
  {
    path: "/seguranca/papeis-funcoes/visualizar/:id",
    name: "seguranca-roles-visualizar",
    component: () => import("../views/seguranca/roles/form"),
    meta: {
      title: i18n.global.t('seg.role'),
      formMode: formMode.View,
      authRequired: true,
      roles: ['seg.role']
    },
  },
];

//
const otherRouters = [
  {
    path: "/seguranca/parametros-acesso",
    name: "seguranca-parametros",
    component: () => import("../views/seguranca/parametros/index"),
    meta: {
      title: i18n.global.t('seg.parametros'),
      authRequired: true,
      roles: ['seg.parametros']
    },
  },
  {
    path: "/seguranca/log-auditoria",
    name: "seguranca-log-auditoria",
    component: () => import("../views/seguranca/logs-auditoria/index"),
    meta: {
      title: i18n.global.t('seg.logaudit'),
      authRequired: true,
      roles: ['seg.logaudit']
    },
  },
  {
    path: "/seguranca/log-auditoria/visualizar/:data/:id",
    name: "seguranca-log-auditoria-view",
    component: () => import("../views/seguranca/logs-auditoria/form"),
    meta: {
      title: i18n.global.t('seg.logaudit'),
      authRequired: true,
      roles: ['seg.logaudit']
    },
  },
]

export default [
  ...routesUsuarios, 
  ...routesRoles,
  ...otherRouters
];