<template>
    <div>
        <BModal id="modal-1" v-model="visible" hideFooter hideHeader noCloseOnBackdrop noCloseOnEsc centered >
            <div class="text-center  p-2 align-middle">{{ text }}<EllipsisSpinner></EllipsisSpinner></div>
        </BModal>
    </div>
</template>
  
<script>
  import store from "@/state/store";
  import EllipsisSpinner from "@/components/ellipsis-spinner.vue";
  export default {
    name: 'WaitModal',
    components: {
      EllipsisSpinner
    },
    computed: {
      visible() {
        return store.getters['notification/modalWaitVisible']
      },
      text() {
        return store.getters['notification/modalWaitText']
      }
    }
  };
</script>
  