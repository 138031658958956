import i18n from "@/utils/i18n";
import { formMode } from "@/utils/form.helpers"

const routesCenariosPadrao = [
  {
    path: "/configuracoes/cenarios-padrao",
    name: "configuracoes-cenarios-padrao",
    component: () => import("../views/configuracoes/cenarios-padrao/index"),
    meta: {
      title: i18n.global.t('conf.cenpad'),
      authRequired: true,
      roles: ['conf.cenpad']
    },
  },
  {
    path: "/configuracoes/cenarios-padrao/criar",
    name: "configuracoes-cenarios-padrao-criar",
    component: () => import("../views/configuracoes/cenarios-padrao/form"),
    meta: {
      title: i18n.global.t('conf.cenpad.create'),
      formMode: formMode.Create,
      authRequired: true,
      roles: ['conf.cenpad.create']
    },
  },
  {
    path: "/configuracoes/cenarios-padrao/editar/:id",
    name: "configuracoes-cenarios-padrao-editar",
    component: () => import("../views/configuracoes/cenarios-padrao/form"),
    meta: {
      title: i18n.global.t('conf.cenpad.edit'),
      formMode: formMode.Edit,
      authRequired: true,
      roles: ['conf.cenpad.edit']
    },
  },
  {
    path: "/configuracoes/cenarios-padrao/visualizar/:id",
    name: "configuracoes-cenarios-padrao-visualizar",
    component: () => import("../views/configuracoes/cenarios-padrao/form"),
    meta: {
      title: i18n.global.t('conf.cenpad'),
      formMode: formMode.View,
      authRequired: true,
      roles: ['conf.cenpad']
    },
  },
];

//
const otherRouters = [
  {
    path: "/configuracoes/dimensoes-controle/moedas",
    name: "configuracoes-moedas",
    component: () => import("../views/configuracoes/dimensao-controle/moedas/index"),
    meta: {
      title: i18n.global.t('conf.dim-ctrl.moeda'),
      authRequired: true,
      roles: ['conf.dim-ctrl.moeda']
    },
  },
  {
    path: "/configuracoes/dimensoes-controle/paises",
    name: "configuracoes-paises",
    component: () => import("../views/configuracoes/dimensao-controle/paises/index"),
    meta: {
      title: i18n.global.t('conf.dim-ctrl.pais'),
      authRequired: true,
      roles: ['conf.dim-ctrl.pais']
    },
  },
  {
    path: "/configuracoes/parametros",
    name: "configuracoes-parametros",
    component: () => import("../views/configuracoes/parametros/index"),
    meta: {
      title: i18n.global.t('conf.params'),
      authRequired: true,
      roles: ['conf.params']
    },
  },
]

export default [
  ...otherRouters,
  ...routesCenariosPadrao
];