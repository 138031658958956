import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export const state = {
    type: null,
    message: null,
    modalWait: false,
    modalWaitText: ''
};

export const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
    },
    clear(state) {
        state.type = null;
        state.message = null;
    },

    toggleModalWait(state, text = '') {
        state.modalWait = !state.modalWait;
        state.modalWaitText = text
    }
};

const toastMsg = (message, type, timeInSeconds = 4, options = {}) => {
    toast(message, {
        ...options,
        "theme": "colored",
        "type": type,
        "position": "top-center",
        "transition": "zoom",
        "dangerouslyHTMLString": true,
        "autoClose": timeInSeconds * 1000
    })
}

export const getters = {
    modalWaitVisible(state) {
        return state.modalWait
    },
    modalWaitText(state) {
        return state.modalWaitText
    }
}

export const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    toggleModalWait({ commit }, text = '') {
        commit('toggleModalWait', text);
    },

    notifyException(_ , { err, timeInSeconds = 4, options = {} }) {
        let msg = err
        if (err && err.response && err.response.data && err.response.data.message) {
            msg = err.response.data.message
        } else if (err && err.response && err.response.message) {
            msg = err.response.message
        } else if (err && err.message) {
            msg = err.message
        } 
        toastMsg(msg, "error", timeInSeconds, options)
    },
    notifyError(_ ,{ message, timeInSeconds = 4, options = {} }) {
        toastMsg(message, "error", timeInSeconds, options)
    },
    notifySuccess(_ , { message, timeInSeconds = 4, options = {} }) {
        toastMsg(message, "success", timeInSeconds, options)
    },
    notifyInfo(_ ,{ message, timeInSeconds = 4, options = {} }) {
        toastMsg(message, "info", timeInSeconds, options)
    },
    notifyWarning(_ ,{ message, timeInSeconds = 4, options = {} }) {
        toastMsg(message, "warning", timeInSeconds, options)
    }
};
