import apiClient from '../../services/api-base'
import i18n from "@/utils/i18n";

export const state = {
  username: localStorage.getItem('eon.username'),
  useremail: localStorage.getItem('eon.useremail'),
  userId: localStorage.getItem('eon.userid'),
  token: localStorage.getItem('eon.token'),
  language: localStorage.getItem('eon.lang'),
};

export const mutations = {
  CHANGE_TOKEN(state, token) {
    state.token = token;
    saveState('eon.token', token);
  },
  CHANGE_USERNAME(state, username) {
    state.username = username;
    saveState('eon.username', username);
  },
  CHANGE_EMAIL(state, useremail) {
    state.useremail = useremail;
    saveState('eon.useremail', useremail);
  },
  CHANGE_USERID(state, userId) {
    state.userId = userId;
    saveState('eon.userid', userId);
  },
  CHANGE_LANG(state, language) {
    state.language = language;
    saveState('eon.lang', language);
    i18n.global.locale = language;
  },
};


export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
      return !!state.token
  },
  userName(state) {
    return state.username
  },
  userEmail(state) {
    return state.useremail
  },
  getLanguage(state) {
    return state.language ?? 'pt_BR'
  },
  token(state) {
    return state.token ?? ''
  },
  roles() {
    if ((state.token ?? '') != '') {
      return JSON.parse(window.atob(localStorage.getItem("eon.token").split(".")[1])).role
    }
    return []
  }
}


export const actions = {
    // Logs in the current user.
    logIn({ commit,  getters }, { email, password } = {}) {
      if (getters.loggedIn)  {
        commit('CHANGE_TOKEN', null)
        commit('CHANGE_USERNAME', null)
        commit('CHANGE_USERID', null)
        commit('CHANGE_EMAIL', null)
      }

      const apiCli = new apiClient();

      return new Promise((resolve, reject) => {
        apiCli.post('account/efetuar-login', { email, password }, false).then((response) => {
            commit('CHANGE_TOKEN', response.data.token)
            commit('CHANGE_USERNAME',  response.data.userName)
            commit('CHANGE_USERID',  response.data.userId)
            commit('CHANGE_EMAIL',  response.data.userEmail)
            resolve(true);
        }).catch(err => {
          console.log(err)
          reject(err);
        })
      });

      
  },
  updateToken({ commit}, token){
    commit('CHANGE_TOKEN', token)
  },
  updateLanguage({ commit}, token){
    commit('CHANGE_LANG', token)
  }
}


// ===
// Private helpers
// ===
function saveState(key, value) {
  if (!value) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}