import routesAccount from './routes-account'
import routesSeguranca from './routes-seguranca'
import routesConfiguracoes from './routes-configuracoes'

const routes = [
  // Dashboards
  {
    path: "/",
    name: "home",
    meta: { title: "Home", authRequired: true },
    component: () => import("../views/home/index.vue"),
  }
];

export default [...routes, ...routesAccount, ...routesSeguranca,  ...routesConfiguracoes];